<template>
  <div class="card">
    <template v-if="done">
      <p>
        パスワード再設定のためのURLを<br />
        以下のメールアドレス宛てに送信しました。
      </p>
      <strong> {{ email }}</strong>
    </template>
    <form v-else @submit.prevent="reset">
      <h2>パスワード再設定依頼</h2>
      <p>ご利用のメールアドレスを入力し、<br />送信ボタンを押してください。</p>
      <div class="field">
        <pv-input label="メールアドレス" type="email" v-model="email" :error="error" float full required />
      </div>
      <div>
        <pv-button type="submit" label="送信" />
        <pv-button label="戻る" class="p-button-text ml-1" @click="back" />
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import http from "axios";

export default {
  setup() {
    const router = useRouter();
    const email = ref("");
    const done = ref(false);
    const error = ref("");

    async function reset() {
      let r = await http.post(`/api/reset_password`, { email: email.value });
      if (r.data.error) {
        error.value = "再設定メールの送信に失敗しました";
        return;
      }

      done.value = true;
    }

    function back() {
      router.push("/login");
    }

    return { email, error, done, reset, back };
  },
}
</script>